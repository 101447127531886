import * as React from 'react';
import styles from './CountdownSection.module.css';
import {Countdown} from './component/Countdown';

export const CountdownSection: React.FC = (props) => {
    const date = new Date('March 10, 2024 16:00:00');
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 120);

    return (
        <div className={styles.countdownSection}>
            <div className={styles.layer2} />
            <div className={styles.countdownWrapper}>
                <Countdown targetDate={date}/>
            </div>
        </div>
    );
};