import * as React from 'react';
import styles from './Partner.module.css';
import {PartnerLogo} from './PartnerLogo';
import RBLogo from '../../assets/website/partner/rb_logo.png';
import NyfterLogo from '../../assets/website/partner/nyfter_logo.png';
import PeepoLogo from '../../assets/website/logo.webp';
import {useIsSmallScreen} from '../../hooks/useIsSmallScreen';

interface PartnerContainerProps {
    padding?: boolean;
}

export const PartnerContainer: React.FC<PartnerContainerProps> = ({padding = true}) => {

    const distance = padding ? 200 : 0;
    const {isSmallScreen} = useIsSmallScreen();

    return (
        <div className={styles.partnerContainer} style={{['--distance' as any]: `${distance}px`}}>
            {isSmallScreen && <>
                <PartnerLogo image={RBLogo}
                />
                <PartnerLogo image={RBLogo}
                />
                <PartnerLogo image={RBLogo}
                />
                <PartnerLogo image={RBLogo}
                />
            </>}
            {!isSmallScreen && <>
                <PartnerLogo image={RBLogo}
                />
                <PartnerLogo image={RBLogo}
                />
                <PartnerLogo image={RBLogo}
                />
                <PartnerLogo image={RBLogo}
                />
                <PartnerLogo image={RBLogo}/>
            </>}
        </div>
    );
};