export const Team = ({
    LAVAL_LEGENDS: 0, // 'Lava Legends',
    EMERALD_KNIGHTS: 1, // 'Emerald Nights',
    OBSIDIAN_OUTLAWS: 2, // 'Obsidian Outlaws',
    NETHERSTORM_RAIDERS: 3, // 'Netherstorm Raiders',
    DIAMOND_DYNASTY: 4, // 'Diamond Dynasty',
    ENDER_WARRIORS: 5, // 'Ender Warriors',
    UNKNOWN: 6,
    TEST_TEAM: 7,
});

export const TEAM_DATA = {
    [Team.LAVAL_LEGENDS]: {
        name: 'Lava Legends',
        color: '#FF4500', // Bright orange-red
        members: ['Wichtiger', 'Misfit'],
    },
    [Team.EMERALD_KNIGHTS]: {
        name: 'Emerald Nights',
        color: '#50C878', // Bright green
        members: ['Nooreax', 'Dimeax'],
    },
    [Team.OBSIDIAN_OUTLAWS]: {
        name: 'Obsidian Outlaws',
        color: '#8a5ffb',
        members: ['Fabo', 'MultiPorl'],
    },
    [Team.NETHERSTORM_RAIDERS]: {
        name: 'Netherstorm Raiders',
        color: '#ff7272', // Deep red
        members: ['CastCrafter', 'Chaosflo'],
    },
    [Team.DIAMOND_DYNASTY]: {
        name: 'Diamond Dynasty',
        color: '#B0E0E6', // Light blue
        members: ['', 'MultiPorl'],
    },
    [Team.ENDER_WARRIORS]: {
        name: 'Ender Warriors',
        color: '#4B0082', // Indigo (mix of black and purple)
        members: ['None1', 'None1']
    },
    [Team.UNKNOWN]: {
        name: 'Unknown',
        color: '#808080', // Neutral gray
        members: ['Unknown2', 'Unknown2']
    },
    [Team.TEST_TEAM]: {
        name: 'Test Team',
        color: '#D3D3D3', // Light gray
        members: ['Test1', 'Test2']
    },
};

export const CURRENT_VERSION = 'versionX';

export const TOURNAMENT_SEEDS = ['11111', '22222', '33333', '44444', '55555'];

export const TEAMS: Record<number, Array<string>> = {
    [Team.LAVAL_LEGENDS]: ['Wichtiger', 'misfity'],
    [Team.EMERALD_KNIGHTS]: ['noooreax', 'Dimeax'],
    [Team.OBSIDIAN_OUTLAWS]: ['TheFabo', 'MultiPori'],
    [Team.NETHERSTORM_RAIDERS]: ['CastCrafter', 'Chaosflo44'],
};

export const PRETTY_NAMES: Record<string, string> = {
    'Dimeax': 'Dimeax',
    'Chaosflo44': 'Chaosflo',
    'CastCrafter': 'CastCrafter',
    'wichtiger': 'Wichtiger',
    'misfity': 'Misfit',
    'MultiPori': 'MultiPorl',
    'noooreax': 'Nooreax',
    'TheFabo': 'Fabo',
};

export function getPlayerImage(name: string) {
    return `https://peeposprint.at/assets/member/${name.toLowerCase()}.png`;
}

export function getTeam(team: number) {
    if(TEAM_DATA.hasOwnProperty(team)) {
        return TEAM_DATA[team];
    }
    return TEAM_DATA[Team.UNKNOWN];
}
