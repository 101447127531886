import {getPlayerImage} from '../../../utils/firebase_consts';
//, fabo, multiporl, misfit
export const MEMBERS = [{
    name: 'Wichtiger',
    team: 0,
    avatar: getPlayerImage('wichtiger'),
    twitter: 'https://twitter.com/WichtigerYT',
    twitch: 'https://twitch.tv/Wichtiger',
    youtube: 'https://youtube.com/Wichtiger'
}, {
    name: 'Misfit',
    team: 0,
    avatar: getPlayerImage('misfit'),
    insta: '',
    twitch: 'https://www.twitch.tv/misfitthereal',
    youtube: 'https://www.youtube.com/channel/UC8hZZgNKbCFeFEA6ixNq06Q',
    twitter: 'https://twitter.com/misfitmcsr'
}, {
    name: 'Dimeax',
    team: 1,
    avatar: getPlayerImage('dimeax'),
    insta: '',
    twitch: 'https://www.twitch.tv/Dimeax',
    youtube: 'https://www.youtube.com/channel/UCPrFGBRlTNcGkJl5-MXNpBg',
    twitter: 'https://twitter.com/Dimeax_'
}, {
    name: 'Nooreax',
    team: 1,
    avatar: getPlayerImage('nooreax'),
    insta: 'https://www.instagram.com/nooreax/?hl=de',
    twitch: 'https://www.twitch.tv/nooreax',
    youtube: 'https://www.youtube.com/c/nooreax/videos',
    twitter: 'https://twitter.com/nooreaxYT'
}, {
    name: 'MultiPorl',
    team: 2,
    avatar: getPlayerImage('multiporl'),
    insta: '',
    twitch: 'https://www.twitch.tv/multiporl',
    youtube: '',
    twitter: ''
}, {
    name: 'Fabo',
    team: 2,
    avatar: getPlayerImage('fabo'),
    insta: 'https://www.instagram.com/dasfabo/',
    twitch: 'https://www.twitch.tv/fabo',
    youtube: 'https://www.youtube.com/user/TheFaboHD',
    twitter: 'https://twitter.com/TheFaboHD'
}, {
    name: 'CastCrafter',
    team: 3,
    avatar: getPlayerImage('castcrafter'),
    insta: 'https://www.instagram.com/castagram_/',
    twitch: 'https://www.twitch.tv/castcrafter/',
    youtube: 'https://www.youtube.com/castcrafter',
    twitter: 'https://twitter.com/CastCrafter'
},  {
    name: 'Chaosflo',
    team: 3,
    avatar: getPlayerImage('chaosflo'),
    insta: 'https://instagram.com/Chaosflo1337',
    twitch: '',
    youtube: 'https://youtube.com/Chaosflo44',
    twitter: 'https://twitter.com/Chaosflo44'
}, ];